<template>
	<div id="privacy" class="container page">
        <p><b>Note: </b>By continuing to use this website you implicitly agree to the terms and conditions detailed below.</p>
        <h3><mark>Privacy Policy</mark></h3>
        <p>This is the website for Chachi, a resource of conversation triggers that make learning exciting. This site is run by people who take privacy issues seriously. Your privacy is important to us.</p>

        <p>This privacy policy explains what information is collected about you, how personal information is used, and how this personal information is stored and protected.</p>

        <h4>The information we collect and how we use it</h4>
        <b>What information is being collected when you use our website?</b>
        <p>We use Plausible Analytics on our website. Plausible minimizes data collection and measures only the most essential data points. We do not track, collect nor store any personal data or personally identifiable information. You can learn about <a href="https://plausible.io/privacy-focused-web-analytics" target="_blank" rel="noopener"> Plausible's data policy here.</a></p>

        <p>Outside of this, we do not currently collect any other information about visitors to our site.</p>

        <p>Full disclosure: We used Google Analytics and Google Tag Manager between March 2020 and January 2021. If you visited our website in this period some personally identifiable information may have been recorded by Google. This information is not accessible to us however.</p>

        <b>What do we do with this information?</b>
        <p>Any data we receive through the above mentioned sources is devoid of any identifying features and is used solely for understanding the site usage statistics and patterns. Any inferences drawn from it are thus used only for refining the site user experience and nothing else.</p>

        <b>How is personal information stored and protected?</b>
        <p>We record very minimal personal data about our users. When a user registers on our website we collect their email address, name and whatever meta information they provide during the registration process. This information is encrypted and stored in our database. All communication between the user and our servers takes place on secure channels only.</p>

        <b>How secure is our website?</b>
        <p>Fairly secure compared to 99.9% of the web, we'd say since we are running on an HTTPS connection. Our hosting servers are also protected by best in class security systems. We hire external contractors to audit our website's security.</p>

        <b>Advertising</b>
        <p>We don't display ads on our website. Our content is not sponsored by any external sponsors either. If this ever changes, we'll let you know the first thing.</p>

        <b>Email</b>
        <p>We use Google Mail for contact. Google have their own security and privacy policies that govern our use of their service. Any email between you and us is encrypted and stored securely on Google servers.</p>

        <b>Cookies</b>
        <p>We use cookies on this site to improve the user experience. All cookie data remains on the user's local machine and is never relayed back to our servers. The cookie data is used mainly to keep track of the user's language preference and to authenticate communication with the server.</p>

        <b>Linking to external sites</b>
        <p>You will encounter links to other sites that are not those which we control. Please be aware that once you click to one of these sites, you are subject to that site's privacy policy and not to ours. You may wish to review the privacy policy for each site you visit.</p>

        <b>Your Consent</b>
        <p>By using this website you consent to the use of any data we collect as set out in this policy. You should review this privacy policy regularly, to note any changes relating to how we collect and use your information.</p>

        <b>Contact Us</b>
        <p>If you have any concerns or queries about the information we hold about you or wish to provide feedback about this site, please feel free to contact us at <code>contact [at] chachi (dot) app</code>.</p>
    </div>
</template>


<script>
export default {
    name: 'Privacy',
    metaInfo: {
        title: 'Privacy Policy',
        titleTemplate: '%s | Chachi',
    },
}
</script>

<style scoped>

</style>